@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
}

.inter-thin {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-normal {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-medium {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-semibold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.inter-black {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.connectSignInBtn {
  border-radius: 0.375rem;
  border: 1px solid rgba(255, 255, 255, 0.08);
  padding-top: 0.75rem;
  padding-left: 1.25rem;
  padding-bottom: 0.75rem;
  padding-right: 1.25rem;
  cursor: pointer;
  min-height: 2.25rem;
  width: 100%;
  font-family: inherit;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* ============================= Scrollbar modification ============================= */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #0c0c0c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 0.375rem;
}

/* ============================= Remove wheel for input number ============================= */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ============================= UNDERLINE TAB ============================= */
.underlineActive {
  @apply absolute -bottom-[-8px] left-0 w-full h-[3px] bg-gold duration-300 rounded-xl;
}

.underlineInactive {
  @apply absolute -bottom-[-8px] left-0 w-full h-[3px] bg-black duration-300 rounded-xl opacity-0;
}

/* ============================= FADE UP ANIMATION ============================= */
.fadeUp {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s, transform 0.5s;
  animation: fadeUp 500ms ease-in-out forwards;
}

/* .del {
  animation-delay: 0.2s;
}

.del2 {
  animation-delay: 0.4s;
} */

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeRight {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.5s, transform 0.5s;
  animation: fadeRight 500ms ease-in-out forwards;
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* ============================= SPINNER LOADER ============================= */
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #9e712c;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

/* ============================= PING ANIMATION ============================= */
/* ACTIVE PING */
.dot {
  /* background: linear-gradient(135deg, #eb1a74, #d55a93 48%, #622631); */
  background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));

  border-radius: 40px;
  width: 0.6rem;
  height: 0.6rem;
}

/* NOT ACTIVE PING */
.inactiveDot {
  /* background: linear-gradient(135deg, #eb1a74, #d55a93 48%, #622631); */
  background: radial-gradient(
    circle at 0.2% 1.8%,
    rgb(255, 90, 8) 0%,
    rgb(88, 0, 0) 100.2%
  );
  border-radius: 40px;
  width: 0.6rem;
  height: 0.6rem;
}

.heartbeat {
  /* background-color: #7932f6; */
  border-radius: 40px;
  width: 0.7rem;
  height: 0.7rem;
  opacity: 0.75;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%,
  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}
